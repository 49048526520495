:root {
    --mainColor: #ff4157;
    --mainColorHover: #ff1d37;
    --back-color: #ecf6fa;
    --buttonColor: var(--mainColor);
    --buttonColorHover: var(--mainColorHover);
    --bannerFontColor: #141414;
    --advantagesFontColor: var(--bannerFontColor);
    --buttonFontColor: #000;
    --counterFontColor: var(--mainColor);
    --buttonBannerColor: var(--mainColor);
    --serviceBackColor: #F9F5F1;

    --menu-color: #ffffff;
    --menuFontColor: #131313;

    --header-color: #000000;
    --headerFontColor: #fff;
    --headerIconColor: var(--mainColor);

    --footer-color: #0a1015;
    --footerFontColor:#4a4a4a;

}

@import "./css/vendor.bundle";
@import "./css/style.scss";
@import "./css/theme";

html{
    scroll-behavior: smooth;
}
.faq-item{
    display: flex;
    justify-content: space-between;

    span{
        font-size: 24px;
        line-height: normal;
        cursor: pointer;
    }
}

.faq{
    box-shadow: 0px 0px 12px 0px rgba(10, 16, 21, 0.03); 
    background-color: #ffffff;
    padding: 30px;
    .collapse{
    }
}
.feature-icon{
    img{
        min-width: 35px;
        height: 35px;
    }
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.no-gutters>.col, .no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.isLoading {
    pointer-events: none;
    //opacity: .5;
    position: relative;
    min-height: 100px;
  
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid var(--mainColor);
      border-left-color: transparent;
      animation: rotate .5s infinite linear;
      z-index: 99;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  
.bg-image img { 
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.form-section{
    position: relative;
    overflow: hidden;
    background-color: #fff;

    .container{
        position: relative;
        z-index: 1;
    }
    .section-head{
        p{
            max-width: 550px;
            font-size: 20px;
        }
    }

    .phone-block{
        padding: 38px 40px;
        background: #FFFFFF;
        box-shadow: 0px 2px 139px rgba(26, 49, 91, 0.25);

        a{
            color: #131313;
            font-weight: 900;
            font-size: 23px;
        }
    }
}
.form-img{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    img{
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
    .form-gradient{
        background: linear-gradient(90deg, #FFFFFF 7%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.form-section-dark{
    background-color: #0E161D;
    .section-head{
        h1, h5{
            color: #fff;
        }
        p{
            color: #fff;
        }
    }
    .form-img{
        .form-gradient{
            background: linear-gradient(90deg, #0E161D 7%, rgba(14, 22, 29, 0) 52%)
        }
    }
    .input{
        color: #fff;
        border-color: rgba(247, 247, 247, 0.623);

        &::placeholder{
            color: #fff;
        }
    }

    form .bdr-b:focus {
        border-bottom-color: #E2E8EB;
    }

    .phone-block{
        background: #0A1015;
        box-shadow: 0px 2px 139px rgba(26, 49, 91, 0.25);
        color: #fff;
    }
}

.pricing-price { 
    border: none;
    padding: 0 ;
}

.pricing-boxed{
    color: #4b4b4b ;

    &:hover{
        color: #4b4b4b ; 
    }
    img{
        border-radius: 10px;
        width: 100%;
        height: 260px;
        object-fit: cover;
    }
}
.pricing-boxed-click{
    cursor: pointer;
}

.back-color{
    background-color:var(--serviceBackColor);
}

.icon-img{
    min-width: 48px;
    height: 48px;
    width: 48px;
}

.banner-item{
    padding: 6px 16px;
    text-transform: uppercase;
    background-color: var(--mainColor);
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    width: fit-content;
    color: var(--advantagesFontColor);
}

.banner-block{

    p{
        color: var(--bannerFontColor);
        font-weight: 400;
        font-size: 30px;
        margin: 14px 0;
        max-width: 650px;
        line-height: 106%;
    }
}

@media (min-width: 576px){
    .banner-wrapper{
        padding: 12px 40px;
    }
}

@media (min-width: 992px){
    .banner-wrapper{
        padding: 12px 40px;
    }
}

@media (min-width: 1280px){
    .banner-wrapper  {
        padding: 20px 40px;
    }
}

@media (min-width: 1600px){
    .banner-wrapper {
        padding: 30px 140px;
    }
}


.bg-image{
        opacity: 1;
}
.container{
        position: relative;
        z-index: 1;
}

.banner-items{
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
    max-width: 800px;
    margin: 0 0 26px;
}
.logo-img{
    margin-bottom: 26px;
}
.position-relative{
    position: relative;
    z-index: 1;

    .offer{
        margin: 60px 0 30px;
        font-weight: 300;
        font-size: 18px;

        span{
            color: #3257F9;
            font-weight: 500;
        }
    }
}
.logo-img{
    img{
        max-width: 200px;
    }
}

.btn-create-light{
    .btn-creative{
        color: #fff;

        &:hover{
            color: #000000;
        }
    }
}
.banner-title{
    color: var(--bannerFontColor);
    font-family: 'rubik', sans-serif;
    max-width: 800px;
}

.pricing-boxed{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pricing-cta{
    margin-top: auto;
}

.counter p{
    margin-top: 10px;
}

.counter-icon{
    color: var(--counterFontColor);
}

.modal-header{
    border: none;
    padding: 20px !important;
}
.modal-form{
    padding: 80px 150px 100px;
    position: relative;
    overflow: hidden;

    .form-content{
        position: relative;
        z-index: 1;

        p{
            max-width: 600px;
        }
    }

    .modal-img{
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.counter-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-success{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .3s;
    transform: translateX(100%);
    opacity: 0;

    i{
        font-size: 60px;
        color: var(--success);
    }

    p{
        font-size: 20px;
    }
}
.form-success-dark{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0E161D;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .3s;
    transform: translateX(100%);
    color: #fff;
    opacity: 0;

    h1{
        color: #fff;
    }
    i{
        font-size: 60px;
        color: var(--success);
    }

    p{
        font-size: 20px;
    }
}
.form-success-visible{
    transform: translateX(0);
    opacity: 1;
}

.phone-wrapper{
    position: relative;

    .phone-block{
        position: absolute;
        white-space: nowrap;

        &:nth-child(1){
            top: -100px;
            left: -100px;
        }
        &:nth-child(2){
            top: 100px;
            left: 100px;
        }
    }
}

.form-hidden{
    overflow: hidden;
}
.banner-after{
    position: absolute;
    width: 720px;
    height: 720px;
    object-fit: contain;
    right: 10%;
    top: 20%;
}

.slider-img{
    width: auto;
    max-height: 700px;
}

.carousel {
    .slider-img{
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        img{
            object-fit: contain;
            width: auto;
        }
    }
}

.project-image{
    img{
        height: 350px;
    }
}
@media(max-width: 1600px){
    .banner-after{
        width: 620px;
        height: 620px;
        right: 100px;
        top: 20%;
    }
    .project-image{
        img{
            height: 300px;
        }
    }
    
}
@media(max-width: 1340px){
    .banner-after{
        width: 520px;
        height: 520px;
        right: 80px;
    }
    .project-image{
        img{
            height: 260px;
        }
    }
}
@media(max-width: 1200px){
    .phone-wrapper{
        .phone-block{
            &:nth-child(1){
                top: -20px;
                left: -80px;
            }
            &:nth-child(2){
                top: 140px;
                left: 80px;
            }
        }
    }
    .banner-after{
        width: 420px;
        height: 420px;
        right: 40px;
        top: 30%;
    }
    .carousel {
        .slider-img{
            max-height: 400px;
        }
    }
    .project-image{
        img{
            height: auto;
        }
    }
}

@media(max-width: 993px){
    .phone-wrapper{
        position: initial;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .phone-block{
            width: fit-content;
            position: initial;
            
        }
    }
    .banner-after{
        display: none;
    }
    .counter h2{
        font-size: 20px;
    }
    .counter-s2{
        justify-content: start;
        text-align: start;
        gap: 20px;
    }
    .modal-form{
        padding: 40px 50px 50px;
    }
}

@media(max-width: 768px){
    .counter-s2 .counter-content{
        margin: 0;
    }
    .counter-s2{
        flex-direction: column;
    }
    .counter h2{
        font-size: 18px;
    }
    .banner-block-creative{
        padding: 100px 0;
    }
    .banner-block p{
        font-size: 20px;
    }
    .banner-item{
        font-size: 14px;
    }
    .modal-form{
        padding: 14px 14px 34px;
    }
    .btn-creative{
        font-size: 14px;
        white-space: nowrap;
    }
}

.custom-modal{
    .modal-content{
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .carousel.carousel-slider .control-arrow{
        opacity: 1;
    }
}

.form-section{
    .section-head{
        max-width: 900px;
    }
}

.col-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.counter{
    ul{
        list-style: disc;
        font-size: 16px;
        font-weight: 600;
        text-align: start;
        padding-left: 20px;
    }
}

.form-light{
    .btn-creative{
        color: #000;
    }
}
.form-section-dark{
    .btn-creative{
        color: #fff;
    }
}
.pricing-feature { 
    margin-bottom: 15px;
    h4{
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        color: #212642;
        font-weight: 600;
        margin: 15px 0;
    }
}

.reviews-card{
    color: #131313;
    font-size: 18px;
    display: flex;
    flex-direction: column;

    h3{
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        margin-top: auto;
    }
    .description{
        margin-bottom: 24px;
    }

    .reviews-subtitle{
        color: var(--mainColor);
        font-weight: 400;
        font-size: 14px;
    }
}

.reviews{
    .slick-track{
        display: flex;
        gap: 36px;

        .slick-slide{
            min-height: 100%;
            height: inherit;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(57, 59, 102, 0.07);
            padding: 30px 37px;

            div{
                height: 100%;
            }
            .reviews-card{
                div{
                    height: auto;
                }
            }
        }
    }
    .slick-dots{
        bottom: -35px;
    }
}

.description{
    img{
        height: auto;
        width: auto;
    }
}

.mt-100{
    margin-top: 100px;
}

.advertising{
    h2{
        margin: 35px 0 27px;
        span{
            color: #fff;
        }
    }
    .subtitle{
        font-size: 20px;
        font-weight: 700;
        color: var(--mainColor);
        max-width: 420px;

        span{
            color: #2D2D2D;
        }
    }
    .banner-title{
        max-width: 830px;
    }
}

.packages{
    background: rgba(39, 41, 46, 0.9);
    color: #fff;
    padding: 68px 40px;

    h3{
        color: #fff;
        font-weight: 700;
        font-size: 30px;
    }

    .hr-line{
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 20px;
        margin-bottom: 43px;

        .line{
            height: 1px;
            background-color: #fff;
            width: 100%;
        }
    }

    .package-item{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .img-flex{
            display: flex;
            font-weight: 700;
            font-size: 18px;
            gap: 13px;
            margin-bottom: 10px;

            img{
                height: 48px;
                width: 48px;
                object-fit: contain;
            }
        }
        .package-list {
            //margin-top: auto;
            margin-left: 75px;
            ul{
            list-style: disc;
            }
        }
    }
}
.text-component{
    padding: 56px 0;
}

@media(max-width: 767px){
    .advertising{
        .subtitle{
            font-size: 16px;
        }
    }
    .packages{
        padding: 30px;
    }
}

.slick-dots li button:before{
    display: none;
}
.logo-white{
    max-width: 220px;
    object-fit: contain;
}
.header-top{
    background: var(--header-color); 
    padding: 6px 62px;
    color: var(--headerFontColor);
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    flex-wrap: wrap;

    a:hover{
        color: var(--headerIconColor);
    }
    i{
        color: var(--headerIconColor);
    }

    .header-left{
        display: flex;
        gap: 20px;
    }
    .header-flex{
        display: flex;
        align-items: center;
        gap: 4px;

        .nowrap{
            white-space: nowrap;
        }
    }

    .header-right{
        display: flex;
        gap: 28px;
        font-size: 18px;
        align-items: center;
    }
}

@media(max-width: 1024px){
    .header-top{
        padding: 6px 16px;
        gap: 20px;
    }
}

@media(max-width: 767px){
    .header-top{
        display: none;
    }
}

.dropdown-lang{
    .btn{
        background-color: transparent !important;
        border: none;
        min-width: auto;
        padding: 0;

        &:hover{
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
        &:focus{
            background-color: transparent;
            border: none;
            box-shadow: none !important;
        }
    }
    .dropdown-item{
        padding: .25rem 0.5rem;
    }
}
.ss-icon {
    width: 60px; 
    height: 60px; 
    border-radius: 50%;
    background: var(--mainColor);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 15px;
    z-index: 100;
    &.clicked #center-bar{
      background: #000;
      outline: none;
    }
    #center-bar{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--mainColor);
        position: relative;
        transition: .3s;
        z-index: 10;
        &:hover, &:focus {
            background: #333;
          }
          &:hover { 
            cursor: pointer;
          }
    }
    a { 
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: var(--mainColor);
        display:flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition: .4s;
        z-index: 9;
        &.whatsapp{
            background-color: #31D84F;
        }
        &.telegram{
            background-color: #23A9EA;
        }

        &:hover, &:focus {
        background-color: #000;
        }
    }
    
    &.clicked a:nth-child(2) { 
      transform:  translateX(-60px) ; 
    }
    &.clicked a:nth-child(3) { 
      transform:  translateX(-110px) ; 
    }
    &.clicked a:nth-child(4) { 
      transform:  translateX(-160px) ; 
    }
  }

.container2{
    position: relative;
    z-index: 1;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1610px;
}

@media(max-width: 1600px){
    .container2{
        max-width: 1410px;
    }
}
@media(max-width: 1400px){
    .container2{
        max-width: 1200px;
    }
}
@media(max-width:912px){
    .image-block img{
        width: 100%;
    }
}

.header-top .header-right{
    svg path{
        fill: var(--mainColor);
    }
}

.menu-sub-none{
    display: none;
}

.header-bottom{
    display: none;
}

@media(max-width: 912px){
    .header-bottom{
        align-items: center;
        display: flex;
        font-size: 18px;
        gap: 28px;
        position: relative;
        padding: 0 25px;
    }
}


.opening-2{
    padding: 100px 0;
    //min-height: 100vh;
    background-color: #F2F2F2;

    .opening-2-wrapper{
        padding: 75px 0 75px 94px;
        border-radius: 80px;
    }

    .opening-2-img{
        img{
            border-radius: 80px; 
        }
    }
    .opening-2-width{
        h1{
            max-width: 650px;
            margin-bottom: 30px;
        }
        .lead, .offer{
            max-width: 600px;
        }
    }
}

.container-opening{
    max-width: 1550px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    margin-top: 75px;
}

@media(max-width: 1600px){
    .container-opening{
        max-width: 1400px;
    }
}

@media(max-width: 1460px){
    .container-opening{
        max-width: 1200px;
    }
    .opening-2 .opening-2-wrapper{
        padding: 75px 0 75px 70px;
    }
    .opening-2 .opening-2-width .lead, .opening-2 .opening-2-width .offer{
        max-width: 550px;
    }
}

@media(max-width: 1260px){
    .container-opening{
        max-width: 1024px;
    }
    .opening-2 .opening-2-wrapper{
        padding: 50px 0 50px 40px;
    }
}
@media(max-width: 1060px){
    .container-opening{
        max-width: 100%;
        margin-top: 40px;
        padding: 24px;
    }
    .opening-2 .opening-2-wrapper{
        background-color: #FADF12;
    }
    .opening-2-img{
        img{
            display: none;
        }
    }
    .opening-2{
        min-height: auto;
    }
}

@media(max-width: 767px){
    .opening-2 .opening-2-wrapper{
        border-radius: 20px;
    }
    .container-opening{
        margin-top: 0;
    }
}
.banner-block p{
    ul{
        list-style: disc;
    }
}

.header-menu{
    ul{
        list-style: none;
    }
}

.btn-banner::after{
    background-color: var(--buttonBannerColor);
}

.portfolio-head{
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 30px;
    &::after{
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: var(--mainColor);
        left: 0;
    }
    .portfolio-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .section-head{
        background-color: #fff;
        margin: 0 !important;
    }
}